/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * This file contains all routes used by the router and the components
 * for EE4E internal environment.
 */

/** Url for accessing the page for creating a new experiment. */
export const NEW_EXPERIMENT_URL = 'new-experiment';
/** Url for accessing the page to input additional information. */
export const ADDITIONAL_INFORMATION_URL = 'additional-information';
/** Url for accessing the page for viewing a pending/completed/failed task. */
export const VIEW_EXPERIMENT_URL = 'view-experiment';
/** Url to the page to start the next task for an existing experiment. */
export const CONTINUE_EXPERIMENT_URL = 'continue-experiment';
/** Url to the page to rerun a splitting task. */
export const RERUN_SPLIT_URL = 'rerun-split';
/** Url to the page to rerun a budget simulation task. */
export const RERUN_BUDGET_SIMULATION_URL = 'rerun-budget-simulation';
/** Routes used by the toolbox. */
export const COVARIATES_ANALYSIS_URL = 'covariates-analysis';
