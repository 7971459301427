/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component} from '@angular/core';
import {CausalImpactAnalysisParameters} from '../../model/causal-impact-analysis-parameters';
import {CausalImpactTaskResult} from '../../model/causal-impact-task-result';
import {RecoverableError} from '../../model/recoverable-error';
import * as Task from '../../model/task';
import {TaskStatusEnum} from '../../model/task-status-enum';
import {BusinessLogicService} from '../../service/business-logic.service';

/** Component for covariates analysis feature (part of toolbox). */
@Component({
  selector: 'app-covariates-analysis',
  standalone: false,
  templateUrl: './covariates-analysis.component.html',
  styleUrls: ['./covariates-analysis.component.scss'],
})
export class CovariatesAnalysisComponent {
  readonly unknownErrorMessage = 'An unknown error has occurred.';
  task!: Task.Task;
  readonly spinnerDiameter = 110;

  constructor(private businessService: BusinessLogicService) {}

  isTaskRunning(): boolean {
    return (
      this.task.taskStatus === TaskStatusEnum.IN_PROGRESS ||
      this.task.taskStatus === TaskStatusEnum.QUEUED
    );
  }

  storeTaskAndStartWorker(causalImpactTask: Task.Task): void {
    this.task = causalImpactTask;
    // Start worker for covariates analysis.
    // It should run until the task is completed or fails.
    this.businessService
      .pollCausalImpactResultForToolbox(this.task.taskId)
      .subscribe((response) => {
        console.log(response);
        this.task.taskStatus = response.task_status;
        this.task.taskResult = response.task_result;
      });
  }

  extractCausalImpactAnalysisParameters(): CausalImpactAnalysisParameters {
    return this.task.taskParameters as CausalImpactAnalysisParameters;
  }

  extractCausalImpactResult(): CausalImpactTaskResult {
    return this.task.taskResult as CausalImpactTaskResult;
  }

  isCompleted(): boolean {
    return this.task.taskStatus === TaskStatusEnum.COMPLETED;
  }

  isLoading(): boolean {
    if (!this.task) {
      return false;
    }
    return (
      this.task.taskStatus === TaskStatusEnum.IN_PROGRESS ||
      this.task.taskStatus === TaskStatusEnum.QUEUED
    );
  }

  protected displayErrorMessage(): string {
    if (!this.task) {
      return this.unknownErrorMessage;
    }
    if (this.task.taskResult) {
      return (
        (this.task.taskResult as RecoverableError).error_message ??
        this.unknownErrorMessage
      );
    }
    if (this.task.taskTraceback) {
      return this.task.taskTraceback;
    }
    return this.unknownErrorMessage;
  }
}
