/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ContinueExperimentComponent} from './continue-experiment/continue-experiment/continue-experiment.component';
import {ExperimentsComponent} from './experiments/experiments.component';
import {AdditionalInformationComponent} from './new-experiment/additional-information/additional-information.component';
import {NewExperimentComponent} from './new-experiment/new-experiment.component';
import {RerunBudgetSimulationComponent} from './rerun/rerun-budget-simulation/rerun-budget-simulation.component';
import {RerunSplittingComponent} from './rerun/rerun-splitting/rerun-splitting.component';
import {CovariatesAnalysisComponent} from './toolbox/covariates-analysis/covariates-analysis.component';
import {ViewExperimentComponent} from './view-experiment/view-experiment/view-experiment.component';

import * as routing from './common/routes';

const routes: Routes = [
  {path: '', component: ExperimentsComponent},
  {path: routing.NEW_EXPERIMENT_URL, component: NewExperimentComponent},
  {
    path: routing.ADDITIONAL_INFORMATION_URL,
    component: AdditionalInformationComponent,
  },
  {
    path: routing.VIEW_EXPERIMENT_URL + '/:experimentId/:action',
    component: ViewExperimentComponent,
  },
  {
    path: routing.CONTINUE_EXPERIMENT_URL + '/:experimentId/:action',
    component: ContinueExperimentComponent,
  },
  {
    path: routing.RERUN_SPLIT_URL + '/:experimentId',
    component: RerunSplittingComponent,
  },
  {
    path: routing.RERUN_BUDGET_SIMULATION_URL + '/:experimentId',
    component: RerunBudgetSimulationComponent,
  },
  {
    path: routing.COVARIATES_ANALYSIS_URL,
    component: CovariatesAnalysisComponent,
  },
  {path: '**', component: ExperimentsComponent},
];

@NgModule({imports: [RouterModule.forRoot(routes)], exports: [RouterModule]})
export class AppRoutingModule {}
