/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Component, Input, OnInit} from '@angular/core';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {TopLevelSpec} from 'vega-lite';

import {convertToLineChartSpec} from '../../../common/kpi-line-chart';
import {DidTaskResult} from '../../../model/did-task-result';
import {StartingPointEnum} from '../../../model/starting-point-enum';
import {HelpMessagesService} from '../../../service/help-messages.service';

interface DidReport {
  group: string;
  prePeriodDailyAverage: number;
  postPeriodDailyAverage: number;
  prePeriodSum: number;
  postPeriodSum: number;
  counterfactual: number;
  relativeLiftPercentage: number;
  numberOfLiftedConversions: number;
  costPerLiftedConversion: number;
}

/**
 * Component for displaying Difference in Difference (DiD) results
 * when viewing an existing experiment.
 */
@Component({
  standalone: false,
  selector: 'app-did-results',
  templateUrl: './did-results.component.html',
  styleUrls: ['./did-results.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY},
    },
  ],
})
export class DidResultsComponent implements OnInit {
  private static readonly CHART_WIDTH = 1000;
  private static readonly CHART_HEIGHT = 400;
  @Input({required: true}) didTaskResult!: DidTaskResult;
  @Input({required: true}) experimentId!: string;
  @Input({required: true}) preExperimentStartDate!: string;
  @Input({required: true}) preExperimentEndDate!: string;
  @Input({required: true}) experimentStartDate!: string;
  @Input({required: true}) experimentEndDate!: string;
  didReportTable: DidReport[] = [];

  readonly didReportLabels = {
    group: 'Group',
    prePeriodDailyAverage: 'PrePeriodDailyAverage',
    postPeriodDailyAverage: 'PostPeriodDailyAverage',
    prePeriodSum: 'PrePeriodSum',
    postPeriodSum: 'PostPeriodSum',
    counterfactual: 'Counterfactual',
    numberOfLiftedConversions: 'NumberOfLiftedConversions',
    relativeLiftPercentage: 'RelativeLiftPercentage',
    costPerLiftedConversion: 'CostPerLiftedConversion',
  };

  displayedColumns = Object.keys(this.didReportLabels);
  kpiGraph!: TopLevelSpec;
  averageKpiGraph!: TopLevelSpec;

  constructor(protected readonly helpMessagesService: HelpMessagesService) {}

  ngOnInit(): void {
    // Prepare the Vega-Lite spec for KPI graph.
    this.kpiGraph = convertToLineChartSpec(
      this.didTaskResult.pre_vs_post_periods_comparison,
      this.preExperimentStartDate,
      this.preExperimentEndDate,
      this.experimentStartDate,
      this.experimentEndDate,
    );

    // Prepare the Vega-Lite spec for average KPI graph.
    this.averageKpiGraph = this.ConvertToVegaLiteBarChartSpec(
      this.didTaskResult.pre_vs_post_period_daily_avg,
    );

    // Prepare the table data for DiD report.
    for (const [groupName, reportForGroup] of Object.entries(
      this.didTaskResult.did_report,
    )) {
      // Extract values to be output in the table.
      const entry = {
        group: groupName,
        prePeriodDailyAverage:
          reportForGroup[this.didReportLabels.prePeriodDailyAverage],
        postPeriodDailyAverage:
          reportForGroup[this.didReportLabels.postPeriodDailyAverage],
        prePeriodSum: reportForGroup[this.didReportLabels.prePeriodSum],
        postPeriodSum: reportForGroup[this.didReportLabels.postPeriodSum],
        counterfactual: reportForGroup[this.didReportLabels.counterfactual],
        numberOfLiftedConversions:
          reportForGroup[this.didReportLabels.numberOfLiftedConversions],
        relativeLiftPercentage:
          reportForGroup[this.didReportLabels.relativeLiftPercentage],
        costPerLiftedConversion:
          reportForGroup[this.didReportLabels.costPerLiftedConversion],
      };
      this.didReportTable.push(entry);
    }
  }

  protected sendImpactMeasurementAction(): StartingPointEnum {
    return StartingPointEnum.IMPACT_MEASUREMENT;
  }

  private ConvertToVegaLiteBarChartSpec(
    averageKpiData: Record<string, Record<string, number>>,
  ): TopLevelSpec {
    const vegaLiteData = [];

    // Transform the data.
    for (const category of Object.keys(averageKpiData)) {
      for (const group of Object.keys(averageKpiData[category])) {
        vegaLiteData.push({
          category,
          group,
          value: averageKpiData[category][group],
        });
      }
    }

    const baseValue =
      averageKpiData['Post-Period Index (Daily Average)']['Control'];

    // Define Vega-Lite specification.
    const vegaLiteSpec: TopLevelSpec = {
      '$schema': 'https://vega.github.io/schema/vega-lite/v5.json',
      description: 'A bar chart',
      data: {values: vegaLiteData},
      params: [
        {name: 'kpiOfControlInPostPeriod', value: baseValue},
        {
          name: 'kpiOfControlInPostPeriodDiff',
          expr: 'kpiOfControlInPostPeriod - 100',
        },
      ],
      layer: [
        {
          mark: 'bar',
          transform: [
            {filter: 'datum.category === "Post-Period Index (Daily Average)"'},
            {calculate: 'datum.value - 100', 'as': 'diffFromPrePeriod'},
            {
              calculate: 'datum.value - kpiOfControlInPostPeriod',
              'as': 'diffFromControlGroup',
            },
          ],
          encoding: {
            x: {
              field: 'group',
              axis: {
                'labelAngle': 0,
              },
              title: 'Groups',
              sort: ['Pre-Period Index', 'Post-Period Index'],
            },
            y: {
              field: 'diffFromPrePeriod',
              type: 'quantitative',
              title: 'Relative KPI Difference from Pre-Period (%)',
              axis: {
                gridColor: {
                  condition: {test: 'datum.value === 0', value: 'black'},
                  value: '#ddd',
                },
              },
            },
            color: {
              field: 'group',
              title: 'Groups',
            },
            tooltip: [
              {field: 'group', title: 'Group'},
              {field: 'value', title: 'Relative KPI (%)'},
              {field: 'diffFromPrePeriod', title: 'Diff from Pre-Period (%)'},
              {field: 'diffFromControlGroup', title: 'Diff from Control (%)'},
            ],
          },
        },
        {
          mark: {type: 'rule', color: 'gray', size: 2, strokeDash: [6, 4]},
          data: {
            values: [{base: baseValue - 100}],
          },
          encoding: {
            y: {field: 'base', type: 'quantitative'},
          },
        },
      ],

      width: DidResultsComponent.CHART_WIDTH,
      height: DidResultsComponent.CHART_HEIGHT,
    };

    return vegaLiteSpec;
  }
}
