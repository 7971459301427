/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup} from '@angular/forms';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {Moment} from 'moment';
import {catchError, of} from 'rxjs';

import * as constants from '../../common/constants';
import {FilePickerComponent} from '../../common/file-picker/file-picker.component';
import * as routes from '../../common/routes';
import {
  experimentNameAndDescriptionAreValid,
  openSnackBarWithMessage,
} from '../../common/utils';
import {CausalImpactAnalysisRequest} from '../../model/causal-impact-analysis-request';
import {DidAnalysisRequest} from '../../model/did-analysis-request';
import {StartingPointEnum} from '../../model/starting-point-enum';
import * as Task from '../../model/task';
import {BusinessLogicService} from '../../service/business-logic.service';
import {FileReaderService} from '../../service/file-reader.service';
import {HelpMessagesService} from '../../service/help-messages.service';

/** Component for creating a new Impact Measurement experiment. */
@Component({
  standalone: false,
  selector: 'app-new-impact-measurement',
  templateUrl: './new-impact-measurement.component.html',
  styleUrls: ['./new-impact-measurement.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: HelpMessagesService.HELP_MESSAGES_SHOW_DELAY,
      },
    },
  ],
})
export class NewImpactMeasurementComponent implements OnInit {
  private readonly destroyedRef = inject(DestroyRef);
  experimentName = '';
  experimentDescription = '';
  allSelected = false;
  testGroupsSelection: string[] = [];
  selectedTestGroups: string[] = [];
  kpiFile: File | null = null;
  selectedMethod: string | null = null;
  estimatedCostsForms = new Map<string, FormGroup>();
  kpiFirstDate: Moment | null = null;
  kpiLastDate: Moment | null = null;
  experimentStartDate: Moment | null = null;
  experimentEndDate: Moment | null = null;
  preExperimentStartDate: Moment | null = null;
  preExperimentEndDate: Moment | null = null;
  readonly estimatedCostDefaultValue = 1000;
  @Input() isCovariatesAnalysis = false;
  @Input() submitButtonDisabled = false;
  @Output() readonly onCovariatesAnalysisStarted =
    new EventEmitter<Task.Task>();

  @ViewChild('selectTestGroup') select!: MatSelect;
  @ViewChild('filePicker') filePicker!: FilePickerComponent;

  readonly snackBarSubmittingImpactMeasurementMessage =
    'Submitting impact measurement task to server.';
  readonly snackBarNoTestGroupsSelectedErrorMessage =
    'Please select at least one test group.';
  readonly snackBarNoAnalysisMethodSelectedErrorMessage =
    'Please select an analysis method.';
  readonly snackBarEstimatedCostsErrorMessage =
    'Some estimated costs for DiD are incorrect.';
  readonly snackBarInvalidStartDateSelectedErrorMessage =
    'The start date selected is invalid.';
  readonly methods = ['Causal Impact', 'Difference-in-Differences'];

  showDiDFields = false;
  showCIFields = false;
  isCustomizedDateSelection = false;
  isMultipleControlGroupsModeEnabled = false;
  confidenceInterval = constants.DEFAULT_CONFIDENCE_INTERVAL;
  confidenceIntervalValues = constants.CONFIDENCE_INTERVAL_VALUES;
  numberOfSeasonsForm = new FormGroup({});
  readonly numberOfSeasonsDefaultValue = constants.DEFAULT_NUMBER_OF_SEASONS;
  readonly minimumNumberOfSeasons = constants.MINIMUM_NUMBER_OF_SEASONS;
  readonly maximumNumberOfSeasons = constants.MAXIMUM_NUMBER_OF_SEASONS;

  allCovariatesInKPIFile: string[] = [];
  testGroupCovariatesSelectionMappings = new Map<
    string,
    FormControl<string[] | null>
  >();

  constructor(
    private readonly fileReaderService: FileReaderService,
    readonly submissionSnackBar: MatSnackBar,
    private businessLogicService: BusinessLogicService,
    private router: Router,
    protected readonly helpMessagesService: HelpMessagesService,
  ) {}

  ngOnInit() {
    if (this.isCovariatesAnalysis) {
      this.selectedMethod = 'Causal Impact';
      this.showCIFields = true;
      this.isMultipleControlGroupsModeEnabled = true;
    }
  }

  storeExperimentName(name: string): void {
    this.experimentName = name;
  }

  storeExperimentDescription(description: string): void {
    this.experimentDescription = description;
  }

  private initiateTestGroupCovariatesSelectionMappings(
    testGroups: string[],
  ): void {
    this.testGroupCovariatesSelectionMappings.clear();
    for (const testGroup of testGroups) {
      this.testGroupCovariatesSelectionMappings.set(
        testGroup,
        new FormControl<string[] | null>([]),
      );
    }
  }

  storeTestGroupsSelection(kpiFile: File | null): void {
    // Reset a checkbox and options.
    this.allSelected = false;
    this.selectedTestGroups = [];
    if (!kpiFile) {
      this.kpiFile = null;
      this.initiateTestGroupCovariatesSelectionMappings([]);
      this.allCovariatesInKPIFile = [];
      if (!this.isCovariatesAnalysis) {
        this.isMultipleControlGroupsModeEnabled = false;
      }
      return;
    }

    const isSingleControlGroupsModeEnabled = !(
      this.selectedMethod === 'Causal Impact' &&
      this.isMultipleControlGroupsModeEnabled
    );

    this.fileReaderService
      .extractAllTestGroups(kpiFile, ',', isSingleControlGroupsModeEnabled)
      .pipe(
        catchError((error) => {
          this.openSnackBarWithMessage(error.message);
          return of([]);
        }),
      )
      .subscribe((testGroups) => {
        this.testGroupsSelection = testGroups;
        // Create a form control for each possible test group.
        this.initiateTestGroupCovariatesSelectionMappings(testGroups);
        this.kpiFile = kpiFile;
      });

    if (!isSingleControlGroupsModeEnabled) {
      this.fileReaderService
        .extractAllCovariates(kpiFile)
        .pipe(
          catchError((error) => {
            this.openSnackBarWithMessage(error.message);
            return of([]);
          }),
        )
        .subscribe((covariates) => {
          this.allCovariatesInKPIFile = covariates;
        });
    }
  }

  storeFirstAndLastDates(kpiFile: File | null): void {
    if (!kpiFile) {
      this.kpiFirstDate = null;
      this.kpiLastDate = null;
      this.experimentStartDate = null;
      this.experimentEndDate = null;
      this.preExperimentStartDate = null;
      this.preExperimentEndDate = null;
      this.isCustomizedDateSelection = false;
      return;
    }
    this.fileReaderService
      .extractFirstAndLastDates(kpiFile)
      .pipe(
        catchError((error) => {
          this.openSnackBarWithMessage(error.message);
          return of([]);
        }),
        takeUntilDestroyed(this.destroyedRef),
      )
      .subscribe((dates) => {
        this.kpiFirstDate = moment.utc(dates[0]);
        this.kpiLastDate = moment.utc(dates[1]);
        // Set the last date in KPI data to a default experiment end date.
        if (!this.isCustomizedDateSelection && !this.experimentEndDate) {
          this.experimentEndDate = this.kpiLastDate;
        }
      });
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  updateAllSelected() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  protected onTestGroupRemoved(testGroup: string): void {
    this.selectedTestGroups = this.selectedTestGroups.filter(
      (group: string) => group !== testGroup,
    );
    this.allSelected = false;
  }

  private openSnackBarWithMessage(message: string): void {
    openSnackBarWithMessage(message, this.submissionSnackBar);
  }

  showOrHideDiDSection(value: string) {
    if (value === 'Difference-in-Differences') {
      this.showDiDFields = true;
      this.showCIFields = false;
    } else if (value === 'Causal Impact') {
      this.showCIFields = true;
      this.showDiDFields = false;
    } else {
      this.showCIFields = false;
      this.showDiDFields = false;
    }
  }

  storeEstimatedCost(estimatedCostRecord: Record<string, FormGroup>): void {
    const parameterName = Object.keys(estimatedCostRecord)[0] as string;
    this.estimatedCostsForms.set(
      parameterName,
      estimatedCostRecord[parameterName],
    );
  }

  storeNumberOfSeasons(numberOfSeasonsRecord: Record<string, FormGroup>): void {
    const parameterName = Object.keys(numberOfSeasonsRecord)[0] as string;
    this.numberOfSeasonsForm = numberOfSeasonsRecord[parameterName];
  }

  private areAllInputFieldsValid(inputFields: Map<string, FormGroup>): boolean {
    for (const form of inputFields.values()) {
      if (form.invalid) {
        return false;
      }
    }
    return true;
  }

  private getNumberOfSeasons() {
    return (
      this.numberOfSeasonsForm.get('fieldValue')?.value ??
      this.numberOfSeasonsDefaultValue
    );
  }

  submitImpactMeasurementExperiment(): void {
    if (
      !this.isCovariatesAnalysis &&
      !experimentNameAndDescriptionAreValid(
        this.experimentName,
        this.experimentDescription,
      )
    ) {
      this.openSnackBarWithMessage(
        constants.SNACKBAR_INVALID_NAME_DESCRIPTION_ERROR_MESSAGE,
      );
      return;
    }

    // A KPI file must be attached.
    if (!this.kpiFile) {
      this.openSnackBarWithMessage(constants.SNACKBAR_KPI_FILE_ERROR_MESSAGE);
      return;
    }

    // At least one test group must be selected.
    if (this.selectedTestGroups.length === 0) {
      this.openSnackBarWithMessage(
        this.snackBarNoTestGroupsSelectedErrorMessage,
      );
      return;
    }

    if (!this.selectedMethod) {
      this.openSnackBarWithMessage(
        this.snackBarNoAnalysisMethodSelectedErrorMessage,
      );
      return;
    }

    if (this.isCustomizedDateSelection) {
      // All dates must be set in customized date selection.
      if (
        !this.isValidDate(
          this.preExperimentStartDate,
          'pre-experiment start date',
        )
      ) {
        return;
      }
      if (
        !this.isValidDate(this.preExperimentEndDate, 'pre-experiment end date')
      ) {
        return;
      }
      if (
        !this.isValidDate(this.experimentStartDate, 'experiment start date')
      ) {
        return;
      }
      if (!this.isValidDate(this.experimentEndDate, 'experiment end date')) {
        return;
      }
    } else {
      // Experiment start and end dates must be set in default date selection.
      if (
        !this.isValidDate(this.experimentStartDate, 'experiment start date')
      ) {
        return;
      }
      if (!this.isValidDate(this.experimentEndDate, 'experiment end date')) {
        return;
      }
      // Set default values for pre-experiment start and end dates.
      if (!this.kpiFirstDate) {
        return;
      } else {
        this.preExperimentStartDate = this.kpiFirstDate;
      }
      this.preExperimentEndDate = this.experimentStartDate
        .clone()
        .subtract(1, 'days');
    }

    // Check if the four dates are in chronological order.
    if (
      !(
        this.preExperimentStartDate.isSameOrBefore(this.preExperimentEndDate) &&
        this.preExperimentEndDate.isBefore(this.experimentStartDate) &&
        this.experimentStartDate.isSameOrBefore(this.experimentEndDate)
      )
    ) {
      this.openSnackBarWithMessage(
        'Selected pre-experiment start, end, experiment start, and end dates are not in chronological order.',
      );
      return;
    }

    if (this.selectedMethod === 'Causal Impact') {
      if (this.numberOfSeasonsForm.invalid) {
        this.openSnackBarWithMessage(
          'The number of seasons is invalid. Please enter a positive integer.',
        );
        return;
      }
      if (this.isMultipleControlGroupsModeEnabled) {
        for (const testGroup of this.selectedTestGroups) {
          if (
            this.testGroupCovariatesSelectionMappings.get(testGroup)?.value
              ?.length === 0 ??
            true
          ) {
            this.openSnackBarWithMessage(
              `Control groups/covariates are empty for test group: ${testGroup}.`,
            );
            return;
          }
        }
      }
    }

    if (
      this.selectedMethod === 'Difference-in-Differences' &&
      !this.areAllInputFieldsValid(this.estimatedCostsForms)
    ) {
      this.openSnackBarWithMessage(this.snackBarEstimatedCostsErrorMessage);
      return;
    }

    if (this.isCovariatesAnalysis) {
      // Creates Impact Measurement task and retrieves it.
      // The taskId is not enough since we need also the task parameters that
      // are not saved in indexedDB.
      const request = this.buildCausalImpactAnalysisTaskRequest();
      this.businessLogicService
        .createCausalImpactTaskForToolbox(request)
        .pipe(takeUntilDestroyed(this.destroyedRef))
        .subscribe({
          next: (value) => {
            const task = value as Task.Task;
            task.taskParameters = request.causal_impact_analysis_parameters;
            this.onCovariatesAnalysisStarted.emit(task);
          },
        });
    } else {
      this.createImpactMeasurementTask()
        .pipe(takeUntilDestroyed(this.destroyedRef))
        .subscribe({
          next: (response) => {
            this.openSnackBarWithMessage(
              this.snackBarSubmittingImpactMeasurementMessage,
            );
            this.router.navigate(
              [
                routes.VIEW_EXPERIMENT_URL +
                  `/${response}/${StartingPointEnum.IMPACT_MEASUREMENT}`,
              ],
              {skipLocationChange: true},
            );
          },
          error: (error) => {
            this.openSnackBarWithMessage(error.message);
          },
        });
    }
  }

  private createImpactMeasurementTask() {
    if (this.selectedMethod === 'Difference-in-Differences') {
      return this.businessLogicService.createDidAnalysisTaskAsNewTask(
        this.experimentName,
        this.experimentDescription,
        this.buildDidAnalysisTaskRequest(),
      );
    } else {
      return this.businessLogicService.createCausalImpactTaskAsNewTask(
        this.experimentName,
        this.experimentDescription,
        this.buildCausalImpactAnalysisTaskRequest(),
      );
    }
  }

  private buildCausalImpactAnalysisTaskRequest(): CausalImpactAnalysisRequest {
    const request: CausalImpactAnalysisRequest = {
      post_experiment_kpi_file: this.kpiFile!,
      causal_impact_analysis_parameters: {
        pre_experiment_start_date:
          this.preExperimentStartDate!.format('YYYY-MM-DD'),
        pre_experiment_end_date:
          this.preExperimentEndDate!.format('YYYY-MM-DD'),
        experiment_start_date: this.experimentStartDate!.format('YYYY-MM-DD'),
        experiment_end_date: this.experimentEndDate!.format('YYYY-MM-DD'),
        kpi_aggregated_by_groups: true,
        test_groups: this.selectedTestGroups,
        groups_structure: {},
        members_to_exclude: {},
        number_of_seasons: this.getNumberOfSeasons(),
        confidence_interval: this.confidenceInterval,
      },
    };
    if (this.isMultipleControlGroupsModeEnabled) {
      request.causal_impact_analysis_parameters.test_group_covariates =
        this.getSelectedCovariates();
    }
    return request;
  }

  private buildEstimatedCosts(): number[] {
    const estimatedCosts: number[] = [];
    for (const key of this.selectedTestGroups) {
      estimatedCosts.push(
        this.estimatedCostsForms.get(key)?.get('fieldValue')?.value ??
          this.estimatedCostDefaultValue,
      );
    }
    return estimatedCosts;
  }

  private buildDidAnalysisTaskRequest(): DidAnalysisRequest {
    return {
      post_experiment_kpi_file: this.kpiFile!,
      did_analysis_parameters: {
        pre_experiment_start_date:
          this.preExperimentStartDate!.format('YYYY-MM-DD'),
        pre_experiment_end_date:
          this.preExperimentEndDate!.format('YYYY-MM-DD'),
        experiment_start_date: this.experimentStartDate!.format('YYYY-MM-DD'),
        experiment_end_date: this.experimentEndDate!.format('YYYY-MM-DD'),
        kpi_aggregated_by_groups: true,
        test_groups_costs: this.buildEstimatedCosts(),
        test_groups: this.selectedTestGroups,
        groups_structure: {},
        members_to_exclude: {},
      },
    };
  }

  clearKPIFileDatesTestControlGroups(): void {
    this.allSelected = false;
    this.selectedTestGroups = [];
    this.kpiFile = null;
    this.filePicker.resetSelectedFile();
    this.testGroupsSelection = [];
    this.kpiFirstDate = null;
    this.kpiLastDate = null;
    this.clearDateSelection();
    this.allCovariatesInKPIFile = [];
    this.testGroupCovariatesSelectionMappings = new Map<
      string,
      FormControl<string[] | null>
    >();
  }

  protected clearDateSelection(): void {
    this.preExperimentStartDate = null;
    this.preExperimentEndDate = null;
    this.experimentStartDate = null;
    this.experimentEndDate = null;
  }

  private isValidDate(date: Moment | null, dateType: string): date is Moment {
    if (!date) {
      this.openSnackBarWithMessage(`Please select the ${dateType}.`);
      return false;
    }
    if (
      !date.isValid() ||
      date.isBefore(this.kpiFirstDate) ||
      date.isAfter(this.kpiLastDate)
    ) {
      this.openSnackBarWithMessage(`The ${dateType} selected is invalid.`);
      return false;
    }
    return true;
  }

  private getSelectedCovariates() {
    const selectedCovariates: Record<string, string[]> = {};
    for (const testGroup of this.selectedTestGroups) {
      selectedCovariates[testGroup] =
        this.testGroupCovariatesSelectionMappings.get(testGroup)?.value ?? [];
    }
    return selectedCovariates;
  }
}
